import React from "react";

const brands = [
  {
    id: 1,
    imgUrl:
      "https://dbcpictures.s3.ap-south-1.amazonaws.com/klm_delhibycycle/thehindu.png",
    redirtectURl:
      "https://www.thehindu.com/sci-tech/health/india-sees-a-bicycle-boom-post-pandemic/article32661457.ece",
  },
  {
    id: 2,
    imgUrl:
      "https://dbcpictures.s3.ap-south-1.amazonaws.com/klm_delhibycycle/The_Times_of_India_Logo_full.png",
    redirtectURl:
      "https://timesofindia.indiatimes.com/entertainment/events/delhi/explore-old-delhi-on-two-wheels-on-world-bicycle-day/articleshow/110592301.cms",
  },
  {
    id: 3,
    imgUrl:
      "https://dbcpictures.s3.ap-south-1.amazonaws.com/klm_delhibycycle/Indian-express.png",
    redirtectURl:
      "https://www.newindianexpress.com/cities/delhi/2024/Jul/19/the-silver-lining-in-the-clouds",
  },
  {
    id: 4,
    imgUrl:
      "https://dbcpictures.s3.ap-south-1.amazonaws.com/klm_delhibycycle/afaqs.png",
    redirtectURl:
      "https://www.afaqs.com/news/advertising/31359_bicycle-branding-klm-airlines-takes-off-on-delhibycycles-two-wheelers",
  },
];
const Media = () => {
  return (
    <div className="media-container">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "-70px",
          // alignContent: "center",
          alignItems: "center",
          justifySelf: "center",
        }}
      >
        <div className="section-title text-center style-two">
          <h2 className=" media-title" style={{ color: "#071C55" }}>
            As Seen On
          </h2>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "full",
            flexWrap: "wrap",
          }}
        >
          {brands.map((item) => (
            <div
              className="media-img"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <a href={item.redirtectURl} target="_blank">
                <img src={item.imgUrl} />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Media;
