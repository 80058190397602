import React, { Component } from "react";
import { connect } from "react-redux";
import { tourCategoryPage } from "../../actions";
import Navbar from "../global-components/navbar";
import PageHeader from "../global-components/page-header";
import DestinationListComponent from "../section-components/destination-list";
import Subscribe from "../section-components/subscribe";
import Footer from "../global-components/footer";

class DestinationList extends Component {
  // Set default tour type as a fallback
  defaultTourType = "mysore-tours";

  componentDidMount() {
    window.scrollTo(0, 0);

    // Use default tour type if not available in the route params
    const tourtype = this.defaultTourType;
    this.props.tourCategoryPage(tourtype);
    console.log(tourtype);
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetch && this.props.fetch !== prevProps.fetch) {
      console.log("Fetching updated data", this.props.fetch);
      const tourtype = this.defaultTourType;
      this.props.tourCategoryPage(tourtype);
    }
  }

  render() {
    const { tourcategorypagedata } = this.props;

    return (
      <div>
        <Navbar />
        {tourcategorypagedata ? (
          <>
            <PageHeader
              headertitle={tourcategorypagedata.fullname}
              backgroundImage={tourcategorypagedata.backgroundImage}
            />
            <DestinationListComponent data={tourcategorypagedata.data} />
          </>
        ) : (
          <p>Loading tour details...</p>
        )}
        <Subscribe />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tourcategorypagedata: state.pageData.tourcategorypagedata,
  fetch: state.modal.fetch,
});

export default connect(mapStateToProps, { tourCategoryPage })(DestinationList);
